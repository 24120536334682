<header class="container-fluid" (blur)="toggleMenu(null)">
  <section class="header-content">
    <nav class="navbar">
      <ul class="navbar-nav navbar-expand menu">
        <li
          class="nav-item"
          *ngFor="let item of headerMenu"
          [ngClass]="item?.child ? 'dropdown' : ''"
          (mouseenter)="toggleMenu(item?.child); "
        >
          <ng-container *ngIf="item.path">
            <a [routerLink]="item.path"
               (click)="toggleMenu(null); setFilter(item.params)">
              {{ item.name | translate }}
            </a>
          </ng-container>
        </li>
        <li *ngIf="!isLoggedUser" class="nav-item">
          <a [routerLink]="addNewItem.path">
            {{ addNewItem.name }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="logo" routerLink="/">
      {{ BRAND_APP }}
      <span>app</span>
      <span class="beta" *ngIf="environment.typeEnv ==='prod'">Beta</span>
      <span class="beta stage" *ngIf="environment.typeEnv ==='stage'">Demo</span>
      <span class="beta dev" *ngIf="environment.typeEnv ==='dev'">Dev</span>
      <span class="beta local" *ngIf="environment.typeEnv ==='local'">Local</span>
    </div>

    <div class="auth" *ngIf="!isLoggedUser;else loggedUser">
      <app-language-select [mode]="'desktop'" [isLoggedInUser]="false"></app-language-select>
      <span (click)="openAuthModal(); toggleMenu(null)">{{ "header.sign_in" | translate }}</span>
    </div>

  </section>
</header>
<div class="menu-block"
     *ngIf="activeChildMenu"
     (mouseleave)="toggleMenu(null)"
>
  <section>
    <div *ngFor="let block of activeChildMenu" class="block">
      <p *ngIf="block.title">{{ block.title | translate }}</p>
      <div class="block-container">
        <div *ngFor="let link of block.links; let i = index">
          <a *ngFor="let link of block.links[i]"
             [routerLink]="link.path"
             (click)="toggleMenu(null); setFilter(link.params)"
          >
            {{ link.name | translate }}
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<header class="mobileMenu" [formGroup]="form" (clickOutside)="closeSearch()">
  <button class="mobile-menu-button" (click)="openMobileMenu()"></button>
  <input type="text"
         autofocus
         placeholder="{{'header.search_placeholder' | translate}}"
         formControlName="input"
  >
  <div class="results">
    <ng-container *ngIf="statesFull.length;else searchNotFound">
      <p *ngFor="let item of statesFull" (click)="selectAddress(item)">
        <span
          [searchHighlight]="form.controls.input.value"
          (click)="selectAddress(item)"
        >{{ item.view }}</span>
      </p>
    </ng-container>
  </div>
  <button class="myzex" [class.logged]="isLoggedUser" (click)="goToAccount(); toggleMenu(null)">
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path
        d="M226-266q62-39 122.5-58T480-343q71 0 133 20t122 57q42-55 59-105.457Q811-421.914 811-480q0-140.247-95.326-235.623Q620.349-811 480.174-811 340-811 244.5-715.623 149-620.247 149-480q0 58 17.027 108.217T226-266Zm253.814-177q-60.971 0-101.893-41.186Q337-525.372 337-585.686T378.107-687.5Q419.215-729 480.186-729t101.893 41.686Q623-645.628 623-585.314T581.893-484q-41.108 41-102.079 41Zm-.219 388q-87.146 0-164.894-33.283-77.747-33.282-135.82-91.552-58.073-58.271-90.977-135.449Q55-392.462 55-480.39q0-87.929 33.5-165.269Q122-723 180-780.5 238-838 315.247-872q77.246-34 165.253-34 88.007 0 165.253 34Q723-838 780.5-780.5 838-723 872-645.594t34 165.328q0 87.922-34 165.094T780.5-180Q723-122 645.465-88.5T479.595-55Z" />
    </svg>
  </button>

</header>
<ng-template #searchNotFound>
  <p *ngIf="form.controls.input.value.length">
    {{ "header.no_search_result" | translate: { '%query': form.controls.input.value.trim() } }}
  </p>
</ng-template>

<ng-template #loggedUser>
  <div class="auth">
    <span routerLink="myzex" rel="nofollow" (click)="toggleMenu(null)">{{ "header.my_cabinet" | translate }}</span>
    <app-language-select [mode]="'desktop'" [isLoggedInUser]="true"></app-language-select>
    <a class="logout" (click)="logout(); toggleMenu(null)" rel="nofollow"></a>
  </div>
</ng-template>
