export function generateAddressUtils(address: string) {
  if (!address) {
    return '';
  }

  const fullAddressArray = address.split(',');
  const addr = [];
  let index = 0;
  if (!isFirstCharacterDigit(fullAddressArray[0].trim())) {
    index = index + 1;
  }

  addr.push(fullAddressArray[index]);
  addr.push(fullAddressArray[index + 1]);
  addr.push(fullAddressArray[index + 2]);
  addr.push(fullAddressArray[fullAddressArray.length - 4]);
  addr.push(fullAddressArray[fullAddressArray.length - 2].replace(/(\d)\s(\d)/g, '$1$2'));

  return addr.filter((item) => item).join(',');
}

export function generateFullAddress(item): string {
  if (!item && !item.address && !item.fullAddress) {
    return '';
  }

  return item.fullAddress?.length
    ? item.fullAddress
    : ([
        item.address.houseNumber,
        item.address.street,
        item.address.neighborhood,
        item.address.city,
        item.address.state,
        item.address.zipCode,
        item.address.country,
      ]
        .filter((i) => i)
        .join(', ') ?? '');
}

function isFirstCharacterDigit(str) {
  if (str.length === 0) {
    return false; // Строка пуста, нет символов для проверки
  }

  const firstCharacter = str.charAt(0);
  return !isNaN(parseInt(firstCharacter, 10));
}
