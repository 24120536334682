import { environment } from '@env';

// services
export const HEALTH = `${environment.server}/health`;
export const GEOIP = 'https://ipinfo.io';
export const NOMINATIM = 'https://nominatim.openstreetmap.org/search.php';

// properties
export const USER_PROPERTIES = `${environment.serverV3}/api/user/properties`;

export const PROPERTY = `${environment.serverV3}/api/properties`;
export const SEARCH_PROPERTIES = `${environment.serverV3}/api/properties/search`;
export const SEARCH_MAP_PROPERTIES = `${environment.serverV3}/api/properties/markers`;

export const LAST_VIEWS_PROPERTIES = `${PROPERTY}/list`;

// saved searches
export const SAVED_SEARCHES = `${environment.server}/api/saved-searches`;

// saved homes
export const SAVED_HOMES = `${environment.serverV3}/api/saved-homes`;

// user
export const REGISTER = `${environment.serverV3}/api/user/sign-up`;
export const SIGN_IN = `${environment.serverV3}/api/user/sign-in`;
export const CONFIRMATION_EMAIL = `${environment.serverV3}/api/user/confirmation-email`;
export const SIGN_OUT = `${environment.serverV3}/api/user/sign-out`;
export const SIGN_OUT_ALL = `${environment.serverV3}/api/user/sign-out-all`;
export const FORGOT = `${environment.serverV3}/api/user/forgot-password`;
export const REFRESH_TOKEN = `${environment.serverV3}/api/user/refresh-token`;
export const ACCOUNT_PROFILE = `${environment.serverV3}/api/user/profile`;
export const ACCOUNT_PROFILE_SET_PASS = `${environment.serverV3}/api/user/set-password`;
export const ACCOUNT_PROFILE_UPDATE_PASS = `${environment.serverV3}/api/user/reset-password`;
export const ACCOUNT_PROFILE_DEACTIVATE = `${environment.serverV3}/api/user/change-active-status`;

// property tours
export const REQUEST_TOUR = `${environment.serverV3}/api/tour/request`;
export const CONFIRM_TOUR = `${environment.serverV3}/api/tour/confirm`;
export const AVAILABLE_TOURS = `${environment.serverV3}/api/tour/available-tours`;

// TODO: Create api ticket
export const ACCOUNT_PROFILE_AVATAR = `${environment.server}/api/user/profile/avatar`; // does not exist
export const ACCOUNT_PROFILE_VERIFY_PHONE = `${environment.server}/api/user/profile/verify-phone`; // does not exist
export const ACCOUNT_PROFILE_LINK_SOCIAL = `${environment.server}/api/user/profile/social`; // does not exist
export const SHARED_LINK = `${environment.server}/api/shared-link`;
export const CONTACT_BUYER = `${environment.server}/api/contact-buyer`;
export const BROKER_INFO = `${environment.server}/api/broker-info`;

// temporary unused
export const IMPORT_PROPERTIES = `${environment.serverV3}/api/parse/import-properties`;
