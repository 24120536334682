export const environment = {
  production: true,
  useBaseAuth: true,

  // Open Street Maps
  mapUrl: 'https://nominatim.openstreetmap.org/search.php?',
  reverseMapUrl: 'https://nominatim.openstreetmap.org/reverse.php?',

  // Tiles
  TILES_MAP: 'https://tiles.zexstage.com/{z}/{x}/{y}.png',

  // backend
  server: 'https://dev-api.zexstage.com',
  serverV3: 'https://dev-mailer.zexstage.com',
  serverFE: 'https://pijaybqcax.zexstage.com',

  propertiesLoadLimit: 40,
  propertiesLoadLimitForMap: 3000,

  imgBucketUrl: 'https://images.zexstage.com/',

  geoIpServiceToken: 'df631ccf6157eb',

  tgToken: '5744332731:AAHlxiaqo-PpQjEP67G4gVCwqPIhsLzctR4',
  tgChatId: '-1002021636950',
  googleClientId: '382028096460-bmjq53meon3rpkhv592rh85hq217kr7r.apps.googleusercontent.com',

  useDemoPhotos: true,
  typeEnv: 'dev',
};
