import { Injectable } from '@angular/core';
import { TelegramService } from './telegram.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private telegramService: TelegramService) {}

  error(data?: any) {
    this.telegramService.sendMessage(data);
  }
}
