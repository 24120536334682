import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  private tgToken = environment.tgToken;
  private tgChatId = environment.tgChatId;

  constructor(private http: HttpClient) {}

  public sendMessage(message: string): void {
    this.http
      .post(
        'https://api.telegram.org/bot' + this.tgToken + '/sendMessage?chat_id=' + this.tgChatId + '&text=' + message,
        {},
      )
      .subscribe();
  }
}
