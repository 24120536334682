import {
  Component,
  EventEmitter,
  HostListener,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IApiDataResponse, INameValue, IPropertyOwn } from '@interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { injectDestroyService, ModalsService, provideDestroyService, YourHomesService } from '@service';
import { catchError, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';
import {
  ModalEnum,
  PropertyConditionEnum,
  PropertyHeatingTypeEnum,
  PropertyHomeTypeEnum,
  PropertyTypeEnum,
} from '@enums';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { getImageUrlThumb, generateAddressUtils, replaceSpecialCharacters } from '@helpers';
import { ToastrService } from 'ngx-toastr';
import { SimpleMapComponent } from '../../../../../shared/component';
import { ImageUploaderV2Component } from '../../../../../shared/component/image-uploader-v2/image-uploader-v2.component';
import { select, Store } from '@ngrx/store';
import { selectHeaderLogged } from '@store';
import { YourHomeDetailsModel } from '../../../../../shared/models/your-home-details.model';
import { lessThanOrEqualValidator } from '../../../../../shared/validators/less-than-or-equal.validator';

@Component({
  selector: 'app-your-property-details',
  templateUrl: './your-property-details.component.html',
  styleUrls: ['./your-property-details.component.scss'],
  providers: [provideDestroyService()],
})
export class YourPropertyDetailsComponent implements OnInit, OnChanges {
  @Output() closePropertyDetails = new EventEmitter<IPropertyOwn>();

  @ViewChild('simplemap') mapElement: SimpleMapComponent;
  @ViewChild('imageUploader') imageUploader: ImageUploaderV2Component;

  protected readonly ModalEnum = ModalEnum;

  private readonly destroy$ = injectDestroyService();
  private originalProperty: IPropertyOwn;
  private propertyFormValue: any;

  public isLoggedIn = true;
  public imageToDelete: number | null;
  public pending = false;
  public pendingImages = false;
  public rawImages: File[] = [];
  public previewImages: Subject<File[]> = new Subject<File[]>();
  public PropertyHomeTypeEnum = PropertyHomeTypeEnum;
  public property: IPropertyOwn;
  public mapCoordinates: { lat: number; lon: number } | null = null;
  public form: FormGroup = new FormGroup({
    id: new FormControl(null),
    price: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    priceCurrency: new FormControl({ value: this.translateService.instant('enums.currency.EUR'), disabled: true }, [
      Validators.required,
    ]),
    homeType: new FormControl(PropertyHomeTypeEnum.HOUSE, [Validators.required]),
    propertyType: new FormControl(PropertyTypeEnum.SALE, [Validators.required]),
    condition: new FormControl(PropertyConditionEnum.FINISHED, [Validators.required]),
    address: new FormGroup({
      country: new FormControl({ value: '', disabled: true }),
      state: new FormControl({ value: '', disabled: true }),
      city: new FormControl({ value: '', disabled: true }),
      street: new FormControl({ value: '', disabled: true }),
      zipCode: new FormControl({ value: '', disabled: true }),
      houseNumber: new FormControl({ value: '', disabled: true }),
    }),
    addressWithoutNumber: new FormControl(null),
    location: new FormGroup({
      lat: new FormControl({ value: '', disabled: true }),
      long: new FormControl({ value: '', disabled: true }),
    }),
    fullAddress: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    bedroom: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(99)]),
    bathroom: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(99)]),
    yearBuild: new FormControl(1980, [Validators.required, Validators.min(1500), Validators.max(2100)]),
    floor: new FormControl(null, [Validators.min(0), Validators.max(999)]), // If there will be underground floors - we will change it )
    totalFloors: new FormControl(null, [Validators.min(0), Validators.max(999)]),
    hasAC: new FormControl(false),
    heating: new FormControl(PropertyHeatingTypeEnum.NONE),
    petsAllowed: new FormControl(false),
    hasGarage: new FormControl(false),
    totalParking: new FormControl(null),
    appliance: new FormControl(false),
    homeSize: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(999999)]),
    livingSize: new FormControl(null, [
      Validators.required,
      Validators.min(1),
      Validators.max(999999),
      lessThanOrEqualValidator('homeSize'),
    ]),
    lotSize: new FormControl(null, [Validators.min(1), Validators.max(999999)]),
    hasVideo: new FormControl(false),
    hasImages: new FormControl(false),
    has3dTour: new FormControl(false),
  });
  public homeTypeList: INameValue[] = Object.keys(PropertyHomeTypeEnum).map((key) => ({
    value: PropertyHomeTypeEnum[key],
    name: this.translateService.instant(`enums.property_home_types.${key}`),
  }));
  public homeConditionList: INameValue[] = Object.keys(PropertyConditionEnum).map((key) => ({
    value: PropertyConditionEnum[key],
    name: this.translateService.instant(`enums.condition.${key}`),
  }));
  public heatingTypeList: INameValue[] = Object.keys(PropertyHeatingTypeEnum).map((key) => ({
    value: PropertyHeatingTypeEnum[key],
    name: this.translateService.instant(`enums.heating.${key}`),
  }));
  public propertyTypeList: INameValue[] = [
    { value: PropertyTypeEnum.SALE, name: this.translateService.instant('enums.property_type.SALE') },
    { value: PropertyTypeEnum.RENT, name: this.translateService.instant('enums.property_type.RENT') },
  ];

  public get formUntouched(): boolean {
    return JSON.stringify(this.form.getRawValue()) === JSON.stringify(this.originalProperty);
  }

  public get isLandProperty(): boolean {
    return [PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get livingSizeAvailable(): boolean {
    return [
      PropertyHomeTypeEnum.HOUSE,
      PropertyHomeTypeEnum.CONDO,
      PropertyHomeTypeEnum.TOWN_HOUSE,
      // PropertyHomeTypeEnum.MULTI_FAMILY,
      PropertyHomeTypeEnum.SMALL_HOUSE,
    ].includes(this.form.get('homeType').value.toString());
  }

  public get homeSizeAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get lotSizeAvailable(): boolean {
    return ![PropertyHomeTypeEnum.CONDO].includes(this.form.get('homeType').value.toString());
  }

  public get yearBuildAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get bedroomAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get bathroomAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get hasGarageAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get totalParkingAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get petsAllowedAvailable(): boolean {
    return (
      ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString()) &&
      this.form.get('propertyType').value.toString() === PropertyTypeEnum.RENT
    );
  }

  public get heatingAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get hasACAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get applianceAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get totalFloorsAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  public get floorAvailable(): boolean {
    return ![PropertyHomeTypeEnum.LAND].includes(this.form.get('homeType').value.toString());
  }

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private yourHomesService: YourHomesService,
    private translateService: TranslateService,
    private modalService: ModalsService,
    private toastr: ToastrService,
    private store: Store,
    private translate: TranslateService,
  ) {
    this.pending = true;
    this.activeRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params?.id && !isNaN(+params.id)) {
        this.loadProperty(params.id);
        return;
      }
      this.pending = false;
    });
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.imageUploader?.onDragOver(event);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    this.imageUploader?.onDrop(event);
  }

  public ngOnInit(): void {
    this.form
      .get('homeType')
      .valueChanges.pipe(startWith(null), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prev, next]) => {
        this.mapCoordinates = null;
        this.mapElement.removeMarker();
        if (!!prev && `${prev}` !== `${next}` && [`${prev}`, `${next}`].includes(PropertyHomeTypeEnum.LAND)) {
          this.form.patchValue({
            address: {
              country: '',
              state: '',
              city: '',
              street: '',
              zipCode: '',
              houseNumber: '',
            },
            location: {
              lat: '',
              long: '',
            },
            fullAddress: '',
          });
        }
        this.managePropertiesVisibility();
        this.form.updateValueAndValidity();
      });

    this.form
      .get('propertyType')
      .valueChanges.pipe(startWith(null), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prev, next]) => {
        this.managePropertiesVisibility();
      });

    this.store
      .pipe(select(selectHeaderLogged), startWith(null), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prev, curr]) => {
        this.isLoggedIn = curr;
        if (prev === null) {
          return;
        }
        if (prev === false && curr === true) {
          if (this.propertyFormValue) {
            this.saveProperty();
          } else {
            this.router.navigate(['myzex/your-homes/details/new']);
          }
        }
        if (prev === true && curr === false) {
          this.router.navigate(['/']);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.property.currentValue) {
      this.form.patchValue(changes.property.currentValue);
    }
  }

  public addressSelected(item: any, moveMap: boolean): void {
    this.form.get('address').patchValue({
      country: item.address?.country,
      state: item.address?.state,
      city: item.address?.city ?? item.address?.town ?? item.address?.village,
      street: item.address?.road ?? item.address?.town,
      zipCode: item.address?.postcode ?? item.address?.zip_code,
      houseNumber: item.address?.house_number ?? '',
    });
    this.form.patchValue({
      fullAddress: item.display_name,
      location: { lat: item.lat, long: item.lon },
      addressWithoutNumber: !item.address?.house_number?.length,
    });
    if (moveMap) {
      this.mapCoordinates = { lat: item.lat, lon: item.lon };
    }
  }

  public view(): void {
    const addressFormatted = generateAddressUtils(replaceSpecialCharacters(this.property.fullAddress))
      .replace(/[^\w]/gi, '-')
      .toLowerCase();

    const path = `/property/${this.property.id}/${this.property.bedroom}-rooms-${addressFormatted.replace('--', '-')}`;
    this.router.navigate([path]);
  }

  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.scrollToFirstInvalidControl();
      return;
    }

    this.form.patchValue({ hasImages: this.property?.images?.length > 0 });
    const formValue = this.form.getRawValue();

    if (!this.homeSizeAvailable) {
      formValue.homeSize = null;
    }
    if (!this.livingSizeAvailable) {
      formValue.livingSize = null;
    }
    if (!this.yearBuildAvailable) {
      formValue.yearBuild = null;
    }
    if (!this.bedroomAvailable) {
      formValue.bedroom = null;
    }
    if (!this.bathroomAvailable) {
      formValue.bathroom = null;
    }
    if (!this.hasGarageAvailable) {
      formValue.hasGarage = null;
    }
    if (!this.totalParkingAvailable) {
      formValue.totalParking = null;
    }
    if (!this.petsAllowedAvailable) {
      formValue.petsAllowed = false; // or API fails
    }
    if (!this.heatingAvailable) {
      formValue.heating = null;
    }
    if (!this.hasACAvailable) {
      formValue.hasAC = null;
    }
    if (!this.applianceAvailable) {
      formValue.appliance = null;
    }
    if (!this.lotSizeAvailable) {
      formValue.lotSize = null;
    }
    if (!this.totalFloorsAvailable) {
      formValue.totalFloors = null;
    }
    if (!this.floorAvailable) {
      formValue.floor = null;
    }

    this.propertyFormValue = {
      ...formValue,
      totalParking: +formValue.totalParking,
      yearBuild: +formValue.yearBuild,
      bedroom: +formValue.bedroom,
      bathroom: +formValue.bathroom,
      floor: +formValue.floor,
      totalFloors: +formValue.totalFloors,
      homeSize: +formValue.homeSize,
      livingSize: +formValue.livingSize,
      lotSize: +formValue.lotSize,
      isActiveStatus: formValue.isActiveStatus ?? false,
    };

    if (this.isLoggedIn) {
      this.saveProperty();
    } else {
      this.modalService.open(ModalEnum.AUTH);
    }
  }

  public cancel(): void {
    this.router.navigate(['myzex/your-homes']);
  }

  public delete(): void {
    this.imageToDelete = null;
    this.modalService.open(ModalEnum.CONFIRM_DELETE);
  }

  public onRemoveImage(imageIndex: number): void {
    if (this.property?.id) {
      this.imageToDelete = imageIndex;
      this.modalService.open(ModalEnum.CONFIRM_DELETE);
      return;
    }

    this.rawImages.splice(imageIndex, 1);
  }

  public onMakeMainImage(imageIndex: number): void {
    if (this.property?.id) {
      this.yourHomesService
        .makeMainImage(this.property.id, this.property.images[imageIndex])
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          // TODO: remove after real API implementation
          this.property.images = [
            this.property.images[imageIndex],
            ...this.property.images.filter((_, i) => i !== imageIndex),
          ];
          this.property.thumbImages = [
            this.property.thumbImages[imageIndex],
            ...this.property.thumbImages.filter((_, i) => i !== imageIndex),
          ];
        });
      return;
    }

    const firstElement = this.rawImages.splice(imageIndex, 1);
    this.rawImages.unshift(firstElement[0]);
  }

  public closeDelete(result: boolean): void {
    this.modalService.close(ModalEnum.CONFIRM_DELETE);
    if (result) {
      const isImageDelete = this.imageToDelete !== null && this.imageToDelete >= 0;

      if (!isImageDelete) {
        this.yourHomesService
          .deleteProperty(this.property.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            this.router.navigate(['myzex/your-homes']);
          });
        return;
      }

      this.pendingImages = true;
      this.yourHomesService
        .removeImage(this.property.id, this.property.images[this.imageToDelete])
        .pipe(
          takeUntil(this.destroy$),
          catchError(() => {
            this.pendingImages = false;
            return EMPTY;
          }),
        )
        .subscribe((resp) => {
          this.property.images.splice(this.imageToDelete, 1);
          this.property.thumbImages.splice(this.imageToDelete, 1);
          this.form.patchValue({ images: this.property.images });
          this.pendingImages = false;
          this.imageToDelete = null;
        });
    }
  }

  public filesSelected(files: File[], redirectAway = false): void {
    if (!this.property?.id) {
      this.rawImages.push(...files);
      this.previewImages.next([]);
      this.previewImages.next(files);
      return;
    }

    const apiCalls: Observable<any>[] = [];
    let successCount = 0;
    let errorCount = 0;

    files.forEach((f) =>
      apiCalls.push(
        this.yourHomesService.uploadFiles(this.property.id, f).pipe(
          tap(
            (resp) => successCount++,
            (error) => errorCount++,
          ),
          takeUntil(this.destroy$),
        ),
      ),
    );
    combineLatest(apiCalls)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (successCount > 0) {
            this.toastr.success(
              `${successCount} ${this.translateService.instant('own_properties_details.images_uploaded')}`,
            );
          }
          if (errorCount > 0) {
            this.toastr.error(`${errorCount} ${this.translateService.instant('own_properties_details.images_failed')}`);
          }

          if (redirectAway) {
            this.router.navigate(['myzex/your-homes']);
            return;
          }

          this.loadProperty(`${this.property.id}`);
        },
        (error) => this.toastr.error(error.statusText),
      );
  }

  public validationError(validationMessage: string): void {
    this.toastr.error(validationMessage);
  }

  public detectCorrectCoordinates(): boolean {
    const lat = this.form.get('location.lat').value;
    const long = this.form.get('location.long').value;
    return !!(lat && long && lat !== 0 && long !== 0);
  }

  public detectCorrectAddress(): boolean {
    if (this.form.get('homeType').value.toString() !== PropertyHomeTypeEnum.LAND) {
      return !!this.form.get('address.houseNumber').value;
    }

    return true;
  }

  private loadProperty(id: string): void {
    this.yourHomesService
      .getProperty(id)
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.cancel();
          return EMPTY;
        }),
      )
      .subscribe((res: IApiDataResponse<IPropertyOwn>) => {
        this.property = res.data;
        this.form.patchValue(new YourHomeDetailsModel(this.property));
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

        this.originalProperty = this.form.getRawValue();
        this.property.thumbImages = this.property?.images?.map((image) => getImageUrlThumb(image)) ?? [];
        this.mapCoordinates = { lat: res.data.location.lat, lon: res.data.location.long };
        this.pending = false;
      });
  }

  private managePropertiesVisibility(): void {
    if (this.homeSizeAvailable) {
      this.form.addControl(
        'homeSize',
        new FormControl(null, [Validators.required, Validators.min(1), Validators.max(999999)]),
      );
    } else {
      this.form.removeControl('homeSize');
    }
    if (this.livingSizeAvailable) {
      this.form.addControl(
        'livingSize',
        new FormControl(null, [Validators.required, Validators.min(1), Validators.max(999999)]),
      );
    } else {
      this.form.removeControl('livingSize');
    }
    if (this.lotSizeAvailable) {
      this.form.addControl('lotSize', new FormControl(null, [Validators.min(1), Validators.max(999999)]));
    } else {
      this.form.removeControl('lotSize');
    }
    if (this.yearBuildAvailable) {
      this.form.addControl(
        'yearBuild',
        new FormControl(1980, [Validators.required, Validators.min(1500), Validators.max(2100)]),
      );
    } else {
      this.form.removeControl('yearBuild');
    }
    if (this.bedroomAvailable) {
      this.form.addControl('bedroom', new FormControl(1, [Validators.required, Validators.min(1), Validators.max(99)]));
    } else {
      this.form.removeControl('bedroom');
    }
    if (this.bathroomAvailable) {
      this.form.addControl(
        'bathroom',
        new FormControl(1, [Validators.required, Validators.min(1), Validators.max(99)]),
      );
    } else {
      this.form.removeControl('bathroom');
    }
    if (this.hasGarageAvailable) {
      this.form.addControl('hasGarage', new FormControl(false));
    } else {
      this.form.removeControl('hasGarage');
    }
    if (this.totalParkingAvailable) {
      this.form.addControl('totalParking', new FormControl(null));
    } else {
      this.form.removeControl('totalParking');
    }
    if (this.petsAllowedAvailable) {
      this.form.addControl('petsAllowed', new FormControl(false));
    } else {
      this.form.removeControl('petsAllowed');
    }
    if (this.heatingAvailable) {
      this.form.addControl('heating', new FormControl(PropertyHeatingTypeEnum.NONE));
    } else {
      this.form.removeControl('heating');
    }
    if (this.hasACAvailable) {
      this.form.addControl('hasAC', new FormControl(false));
    } else {
      this.form.removeControl('hasAC');
    }
    if (this.applianceAvailable) {
      this.form.addControl('appliance', new FormControl(false));
    } else {
      this.form.removeControl('appliance');
    }
    if (this.totalFloorsAvailable) {
      this.form.addControl('totalFloors', new FormControl(null, [Validators.min(0), Validators.max(999)]));
    } else {
      this.form.removeControl('totalFloors');
    }
    if (this.floorAvailable) {
      this.form.addControl('floor', new FormControl(null, [Validators.min(0), Validators.max(999)]));
    } else {
      this.form.removeControl('floor');
    }
  }

  private saveProperty(): void {
    this.pending = true;
    if (!this.propertyFormValue) {
      return;
    }

    const apiCall: Observable<any> = this.property?.id
      ? this.yourHomesService.editProperty(this.propertyFormValue)
      : this.yourHomesService.createProperty(this.propertyFormValue);

    apiCall
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.toastr.error(this.translate.instant('own_properties_details.error_message'));
          this.pending = false;
          return EMPTY;
        }),
      )
      .subscribe(
        (res) => {
          if (!this.property?.id) {
            if (this.rawImages?.length) {
              this.property = res.data;
              setTimeout(() => this.filesSelected(this.rawImages, true));
            }
            this.router.navigate([`myzex/your-homes/details/${res.data.id}`]);
          }
          this.originalProperty = this.form.getRawValue();
          this.pending = false;
          this.propertyFormValue = null;
          this.toastr.success(this.translate.instant('own_properties_details.success_message'));
        },
        (error) => {
          this.toastr.error(this.translate.instant('own_properties_details.error_message'));
          this.pending = false;
        },
      );
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = document.querySelector('.ng-invalid');
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInvalidControl.focus();
    }
  }

  activateProperty() {
    this.yourHomesService
      .activateProperty(this.form.get('id').value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.property.isActiveStatus = true;
        this.toastr.success(this.translate.instant('own_properties.property_activated'));
      });
  }
}
