import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypeSocialEnum } from '@enums';
import { IAccountProfile } from '@interfaces';
import { Observable } from 'rxjs';
import {
  ACCOUNT_PROFILE,
  ACCOUNT_PROFILE_AVATAR,
  ACCOUNT_PROFILE_DEACTIVATE,
  ACCOUNT_PROFILE_LINK_SOCIAL,
  ACCOUNT_PROFILE_SET_PASS,
  ACCOUNT_PROFILE_UPDATE_PASS,
  ACCOUNT_PROFILE_VERIFY_PHONE,
  CONFIRMATION_EMAIL,
  FORGOT,
} from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  public getProfile(): Observable<IAccountProfile> {
    return this.http.get<IAccountProfile>(ACCOUNT_PROFILE);
  }

  public updateProfile(body: any): Observable<IAccountProfile> {
    return this.http.patch<IAccountProfile>(ACCOUNT_PROFILE, body);
  }

  public verifyEmail(): Observable<any> {
    return this.http.post<any>(`${CONFIRMATION_EMAIL}`, null);
  }

  public verifyPhone(): Observable<any> {
    return this.http.post<any>(`${ACCOUNT_PROFILE_VERIFY_PHONE}`, null);
  }

  public setPassword(body: { password: string; token: string }): Observable<any> {
    return this.http.put<any>(`${ACCOUNT_PROFILE_SET_PASS}`, body);
  }

  public changePassword(email: string): Observable<any> {
    return this.http.post<any>(`${ACCOUNT_PROFILE_UPDATE_PASS}`, { email });
  }

  // test

  public forgotPassword(email: string, hash: string): Observable<any> {
    return this.http.post<any>(`${FORGOT}`, { email, hash });
  }

  public deactivateAccount(): Observable<any> {
    return this.http.put<any>(`${ACCOUNT_PROFILE_DEACTIVATE}`, { active: false });
  }

  public linkSocial(type: TypeSocialEnum): Observable<any> {
    return this.http.post<any>(`${ACCOUNT_PROFILE_LINK_SOCIAL}/${type}`, null);
  }

  public unlinkSocial(type: TypeSocialEnum): Observable<any> {
    return this.http.delete<any>(`${ACCOUNT_PROFILE_LINK_SOCIAL}/${type}`);
  }

  public uploadAvatar(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`${ACCOUNT_PROFILE_AVATAR}`, formData);
  }
}
