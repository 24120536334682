<div [class.pseudo-article]="!isLoggedIn">
  <div class="header-panel">
    <button *ngIf="!pending && formUntouched && property?.isActiveStatus" class="header-button save-button"
            (click)="view()">{{ 'own_properties_details.view' | translate }}
    </button>
    <button *ngIf="!pending && formUntouched && !property?.isActiveStatus && form.valid && detectCorrectCoordinates()"
            class="header-button save-button"
            (click)="activateProperty()">{{ 'own_properties_details.activate' | translate }}
    </button>
    <button *ngIf="!pending" class="header-button save-button"
            [disabled]="!detectCorrectAddress() || !detectCorrectCoordinates() || pending || form.get('location').get('long').invalid || form.invalid"
            (click)="save()">{{ (property?.id ? 'own_properties_details.save' : 'own_properties_details.create') | translate }}
    </button>
    <button *ngIf="!pending" class="header-button cancel-button"
            (click)="cancel()">{{ 'own_properties_details.cancel' | translate }}
    </button>
  </div>
  <div class="content-panel" [formGroup]="form">

    <section>
      <header>
        <h2>{{ 'own_properties_details.general_info' | translate }}</h2>
      </header>
      <article>
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.price_title' | translate }}</p>
          <p class="description">{{ 'own_properties_details.price_description' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="price" numbersOnly />
        </div>
      </article>
      <article>
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.currency_title' | translate }}</p>
          <p class="description">{{ 'own_properties_details.currency_description' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="priceCurrency" />
        </div>
      </article>
      <article>
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.property_type_title' | translate }}</p>
          <p class="description">{{ 'own_properties_details.property_type_description' | translate }}</p>
        </div>
        <div class="value-container">
          <select formControlName="propertyType">
            <option *ngFor="let item of propertyTypeList" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </article>
      <article>
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.home_type_title' | translate }}</p>
          <p class="description">{{ 'own_properties_details.home_type_description' | translate }}</p>
        </div>
        <div class="value-container">
          <select formControlName="homeType">
            <option *ngFor="let item of homeTypeList" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </article>
      <article *ngIf="!isLandProperty">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.condition_title' | translate }}</p>
          <p class="description">{{ 'own_properties_details.condition_description' | translate }}</p>
        </div>
        <div class="value-container">
          <select formControlName="condition">
            <option *ngFor="let item of homeConditionList" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </article>
      <article *ngIf="yearBuildAvailable">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.build_year' | translate }}</p>
          <p class="description">{{ 'Please indicate the year of construction of the object' | translate }}</p>

        </div>
        <div class="value-container">
          <input type="text" formControlName="yearBuild" numbersOnly />
        </div>
      </article>
      <article *ngIf="bedroomAvailable">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.bedroom_number' | translate }}</p>
          <p class="description">{{ 'Total number of bedrooms' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="bedroom" numbersOnly />
        </div>
      </article>
      <article *ngIf="bathroomAvailable">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.bathroom_number' | translate }}</p>
          <p class="description">{{ 'Total number of bathrooms' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="bathroom" numbersOnly />
        </div>
      </article>
      <article *ngIf="homeSizeAvailable">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.home_size' | translate }}</p>
          <p class="description">{{ 'Total area of the object' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="homeSize" numbersOnly />
        </div>
      </article>
      <article *ngIf="livingSizeAvailable">
        <div class="description-container">
          <p class="label required-field">{{ 'own_properties_details.living_size' | translate }}</p>
          <p class="description">{{ 'Living area of the object' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="livingSize" numbersOnly />
        </div>
      </article>
      <article class="address-container">
        <div class="description-container">
          <div class="description-section">
            <p class="label required-field">{{ 'own_properties_details.address' | translate }}</p>
            <p *ngIf="!isLandProperty" class="description">{{ 'own_properties_details.address_title' | translate }}</p>
            <p *ngIf="isLandProperty"
               class="description">{{ 'own_properties_details.address_description' | translate }}</p>
          </div>


          <div class="address-section value-container">
            @if (isLandProperty) {
              <input
                readonly
                type="text"
                formControlName="fullAddress"
                placeholder="{{'own_properties_details.search_placeholder' | translate}}"
                class="selected-address"
                [class.ng-invalid]="form.controls['fullAddress'].errors?.required || !detectCorrectCoordinates()"
                [class.ng-touched]="form.controls['fullAddress'].touched"
              />
            } @else {
              <app-address-search-input
                [class.ng-invalid]="form.controls['fullAddress'].errors?.required  || !detectCorrectCoordinates()"
                [class.ng-touched]="form.controls['fullAddress'].touched"
                (addressSelected)="addressSelected($event, true)"
              ></app-address-search-input>
            }
          </div>

        </div>
        <div class="map-value-container">
          <div class="map-container">
            @if (isLandProperty) {
              <fieldset style="height: 400px">
                <app-simple-map
                  #simplemap
                  [disabled]="false"
                  [navigateToCoordinates]="mapCoordinates"
                  (selectedAddressChanged)="addressSelected($event, false)"
                ></app-simple-map>
              </fieldset>
            } @else {
              <fieldset>
                <app-simple-map
                  #simplemap
                  [disabled]="false"
                  [navigateToCoordinates]="mapCoordinates"
                  (selectedAddressChanged)="addressSelected($event, false)"
                ></app-simple-map>
              </fieldset>
            }
          </div>


          <div *ngIf="!isLandProperty" class="value-container address" formGroupName="address">
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.country' | translate }}</label>
              <input readonly type="text" formControlName="country" />
            </fieldset>
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.state' | translate }}</label>
              <input readonly type="text" formControlName="state" />
            </fieldset>
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.city' | translate }}</label>
              <input readonly type="text" formControlName="city" />
            </fieldset>
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.street' | translate }}</label>
              <input readonly type="text" formControlName="street" />
            </fieldset>
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.zip_code' | translate }}</label>
              <input readonly type="text" formControlName="zipCode" />
            </fieldset>
            <fieldset>
              <label class="field-label">{{ 'own_properties_details.house_number' | translate }}</label>
              <input type="text" formControlName="houseNumber" [readonly]="!form.get('addressWithoutNumber').value" />
            </fieldset>
          </div>
        </div>
        <div class="description-section" style="color:#a91712;" *ngIf="!pending && !detectCorrectAddress()">
          {{ 'The address is not complete. Please place a marker on the map at your object or the nearest building to your site.' | translate }}
        </div>

      </article>
    </section>

    <section>
      <header>
        <h2>{{ 'own_properties_details.additional_info' | translate }}</h2>
      </header>
      <article *ngIf="floorAvailable">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.floor' | translate }}</p>
          <p class="description">{{ 'Please indicate the floor of the object' | translate }}</p>

        </div>
        <div class="value-container">
          <input type="text" formControlName="floor" numbersOnly />
        </div>
      </article>
      <article *ngIf="totalFloorsAvailable">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.total_floors' | translate }}</p>
          <p class="description">{{ 'Please indicate the total number of floors in the building' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="totalFloors" numbersOnly />
        </div>
      </article>
      <article *ngIf="lotSizeAvailable">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.lot_size' | translate }}</p>
          <p class="description">{{ 'Total area of the land' | translate }}</p>
        </div>
        <div class="value-container">
          <input type="text" formControlName="lotSize" numbersOnly />
        </div>
      </article>
      <article *ngIf="heatingAvailable">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.heating' | translate }}</p>
          <p class="description">{{ 'Please indicate the type of heating in the object' | translate }}
        </div>
        <div class="value-container">
          <select formControlName="heating">
            <option *ngFor="let item of heatingTypeList" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </article>
      <article *ngIf="totalParkingAvailable">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.parking_number' | translate }}</p>
          <p class="description">{{ 'Total number of parking spaces' | translate }}
        </div>
        <div class="value-container">
          <!-- : TODO I dont know cases for this: [readonly]="!form.controls['hasGarage'].value"-->
          <input type="text" formControlName="totalParking" numbersOnly />
        </div>
      </article>
      <article *ngIf="hasGarageAvailable" class="checkbox">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.has_garage' | translate }}</p>
          <p class="description">{{ 'Is there a garage on the property' | translate }}
        </div>
        <div class="value-container">
          <app-checkbox
            [formControlName]="'hasGarage'"
            [ch]="form.controls['hasGarage'].value"
          ></app-checkbox>
        </div>
      </article>
      <article *ngIf="petsAllowedAvailable" class="checkbox">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.pets_allowed' | translate }}</p>
          <p class="description">{{ 'Are pets allowed in the property' | translate }}
        </div>
        <div class="value-container">
          <app-checkbox
            [formControlName]="'petsAllowed'"
            [ch]="form.controls['petsAllowed'].value"
          ></app-checkbox>
        </div>
      </article>
      <article *ngIf="hasACAvailable" class="checkbox">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.cooling' | translate }}</p>
          <p class="description">{{ 'Is there air conditioning in the property' | translate }}
        </div>
        <div class="value-container">
          <app-checkbox
            [formControlName]="'hasAC'"
            [ch]="form.controls['hasAC'].value"
          ></app-checkbox>
        </div>
      </article>
      <article *ngIf="applianceAvailable" class="checkbox">
        <div class="description-container">
          <p class="label">{{ 'own_properties_details.appliences' | translate }}</p>
          <p class="description">{{ 'Are there any appliances in the property' | translate }}
        </div>
        <div class="value-container">
          <app-checkbox
            [formControlName]="'appliance'"
            [ch]="form.controls['appliance'].value"
          ></app-checkbox>
        </div>
      </article>
    </section>

    <section>
      <header>
        <h2>{{ 'own_properties_details.description_title' | translate }}</h2>
      </header>
      <article class="description-textarea">
        <div class="value-container">
          <textarea formControlName="description"></textarea>
        </div>
        <div class="promo">
          <p class="development">{{ 'Advertising block is coming soon' | translate }} ;)</p>
          <div class="filter">
            <div class="vip">
              <div>
                <span>VIP</span>
                <span>1 days</span>
              </div>
              <div>
                <span>VIP</span>
                <span>3 days</span>
              </div>
              <div>
                <span>VIP</span>
                <span>7 days</span>
              </div>
              <div>
                <span>VIP</span>
                <span>14 days</span>
              </div>
              <div>
                <span>VIP</span>
                <span>30 days</span>
              </div>
            </div>
            <div class="premium">
              <div>
                <span class="premium-title">Standard</span>
                <span>Just ads without privilegies</span>
              </div>
              <div>
                <span class="premium-title">Premium</span>
                <span>Label premium | Auto update ads</span>
              </div>
            </div>
            <div class="top">
              <div>
                <span>TOP</span>
                <span>1 days</span>
              </div>
              <div>
                <span>TOP</span>
                <span>3 days</span>
              </div>
              <div>
                <span>TOP</span>
                <span>7 days</span>
              </div>
              <div>
                <span>TOP</span>
                <span>14 days</span>
              </div>
              <div>
                <span>TOP</span>
                <span>30 days</span>
              </div>
            </div>
            <div class="email-recommended">
              <span class="emr-title">Recommendation</span>
              <span>Send ads with your object inside email to all users, that interested objects near your reality</span>
            </div>
            <div class="custom-ads">
              <span class="custom-ads-title">Custom ads</span>
              <span>Please, send to us your variants for promotion. We're happy to consider it!</span>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <header>
        <h2>{{ 'own_properties_details.photos' | translate }}</h2>
      </header>
      <article class="photos">
        <app-images-list
          [previewMode]="!property?.id"
          [previewImages]="previewImages"
          [images]="property?.thumbImages"
          [pending]="pendingImages"
          (removeImage)="onRemoveImage($event)"
          (makeMainImage)="onMakeMainImage($event)"
        ></app-images-list>
      </article>
      <article class="photos" [class.fixed-height]="!property?.id">
        <app-image-uploader-v2
          #imageUploader
          (filesSelected)="filesSelected($event)"
          (validationError)="validationError($event)"
        ></app-image-uploader-v2>
      </article>
    </section>

    <section *ngIf="property?.id">
      <article class="d-flex justify-content-end delete-property">
        <div class="description-container d-flex flex-column align-items-end">
          <p class="label delete-label"
             (click)="delete()">{{ 'own_properties_details.delete_property' | translate }}</p>
          <p class="description">{{ 'own_properties_details.delete_property_description' | translate }}</p>
        </div>
      </article>
    </section>
  </div>
</div>

<app-basic-modal [modalId]="ModalEnum.CONFIRM_DELETE">
  <app-modal-confirm
    [title]="((imageToDelete !== null && imageToDelete !== undefined) ? 'own_properties_details.delete_image_modal' : 'own_properties_details.delete_property_modal') | translate"
    [description]="((imageToDelete !== null && imageToDelete !== undefined) ? 'own_properties_details.delete_image_modal_description' : 'own_properties_details.delete_property_modal_description') | translate"
    [applyButtonText]="'own_properties_details.button_delete_modal' | translate"
    [cancelButtonText]="'own_properties_details.button_cancel_modal' | translate"
    (closeModal)="closeDelete($event)"
  ></app-modal-confirm>
</app-basic-modal>
